<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 117px);" v-if="this.usergroup === 'supplier'">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbarSupplier"
        :toolbarClick="clickToolbar"
        :allowGrouping="true"
        :groupSettings="groupSettings"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :selectionSettings="selectionOptions"
        :rowSelected="onRowSelected"
        :rowDeselected="onRowDeselected"
        :recordDoubleClick="recordDoubleClick"
        :editSettings="editSettings"
        :queryCellInfo="queryCellInfo"
        :actionComplete="actionComplete"
      >
        <e-columns>
          <e-column
            type="checkbox"
            width="50"
          ></e-column>
          <e-column
            field="created"
            headerText="Datum"
            textAlign="Center"
            width="165"
          ></e-column>
          <e-column
            field="approved"
            headerText="<i class='e-btn-icon e-icon-check-box-2 e-icons e-icon-left table-header-icon' data-toggle='tooltip' data-placement='top' title='Anfrage freigegeben'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="41"
            :customAttributes="statusColumnAttributes"
          ></e-column>
          <e-column
            field="id"
            headerText="Anfragen-Nr."
            textAlign="Left"
            :isPrimaryKey="true"
          ></e-column>
          <e-column
            field="supplier_associate"
            headerText="Außendienst"
            textAlign="Left"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="trader_associate"
            headerText="Innendienst"
            textAlign="Left"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="trader_company"
            headerText="Händler"
            textAlign="Left"
          ></e-column>
          <e-column
            field="client"
            headerText="Kunden-ID"
            textAlign="Right"
          ></e-column>
          <e-column
            field="client_company"
            headerText="Endkunde"
            textAlign="Left"
            :customAttributes="clientColumnAttributes"
          ></e-column>
          <e-column
            field="product_label"
            headerText="Produkt"
            textAlign="Left"
          ></e-column>
          <e-column
            field="product_item_number"
            headerText="Artikelnummer"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="product_item_variant"
            headerText="Variante"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="client_sample"
            headerText="Muster"
            textAlign="Left"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="client_catalog"
            headerText="Katalog"
            textAlign="Left"
            :disableHtmlEncode="false"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <section style="height: calc(100vh - 117px);" v-if="this.usergroup === 'trader'">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbarTrader"
        :toolbarClick="clickToolbar"
        :allowGrouping="true"
        :groupSettings="groupSettings"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :selectionSettings="selectionOptions"
        :rowSelected="onRowSelected"
        :rowDeselected="onRowDeselected"
        :recordDoubleClick="recordDoubleClick"
        :editSettings="editSettings"
        :queryCellInfo="queryCellInfo"
        :actionComplete="actionComplete"
      >
        <e-columns>
          <e-column
            type="checkbox"
            width="50"
          ></e-column>
          <e-column
            field="created"
            headerText="Datum"
            textAlign="Center"
            width="165"
          ></e-column>
          <e-column
            field="approved"
            headerText="<i class='e-btn-icon e-icon-check-box-2 e-icons e-icon-left table-header-icon' data-toggle='tooltip' data-placement='top' title='Anfrage freigegeben'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="41"
            :customAttributes="statusColumnAttributes"
          ></e-column>
          <e-column
            field="finalized"
            headerText="<i class='e-btn-icon e-icon-check-box e-icons e-icon-left table-header-icon' data-toggle='tooltip' data-placement='top' title='Anfrage abgeschlossen'></i>"
            textAlign="Left"
            :disableHtmlEncode="false"
            width="41"
            :customAttributes="statusColumnAttributes"
          ></e-column>
          <e-column
            field="id"
            headerText="Anfragen-Nr."
            textAlign="Left"
            :isPrimaryKey="true"
          ></e-column>
          <e-column
            field="client_associate"
            headerText="MA Int."
            textAlign="Left"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="client"
            headerText="Kunden-ID"
            textAlign="Right"
          ></e-column>
          <e-column
            field="client_company"
            headerText="Endkunde"
            textAlign="Left"
            :customAttributes="clientColumnAttributes"
          ></e-column>
          <e-column
            field="supplier_company"
            headerText="Hersteller"
            textAlign="Left"
          ></e-column>
          <e-column
            field="product_label"
            headerText="Produkt"
            textAlign="Left"
          ></e-column>
          <e-column
            field="product_item_number"
            headerText="Artikelnummer"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="product_item_variant"
            headerText="Variante"
            textAlign="Left"
            :visible="false"
          ></e-column>
          <e-column
            field="client_sample"
            headerText="Muster"
            textAlign="Left"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="client_catalog"
            headerText="Katalog"
            textAlign="Left"
            :disableHtmlEncode="false"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: New -->
    <mdb-modal
      id="add-request"
      fullHeight
      position="top"
      scrollable
      removeBackdrop
      :show="showAdd"
      @close="closeAdd"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title v-show="addRequest.requestId === 'new'">Anfrage erstellen</mdb-modal-title>
        <mdb-modal-title v-show="addRequest.requestId !== 'new'">Anfrage {{ addRequest.requestId }} konfigurieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="pt-0">
        <form id="add-form" novalidate @submit.prevent="submitRequest" @keydown.enter.prevent>
            <mdb-row class="mt-3">
              <mdb-col col="4" style="border-right: 1px solid #ccc;">
                <legend><mdb-icon icon="user" fas class="blue-text" /> Besucher & Anfrage-Details</legend>
                <mdb-card class="mt-3" v-show="addRequest.clientName !== '-'">
                  <mdb-card-body class="pt-2 pb-2">
                    <p class="small mb-0 dark-grey-text">{{ addRequest.clientCompany }}</p>
                    <h5 class="mb-0 dark-grey-text">{{ addRequest.clientName }}</h5>
                  </mdb-card-body>
                  <mdb-card-footer class="pt-2 pb-2 grey-text">
                    eingeladen von {{ addRequest.traderCompany }}
                  </mdb-card-footer>
                </mdb-card>
                <mdb-input id="search-client-input" outline size="lg" type="tel" inputmode="numeric" pattern="[0-9]*" autocomplete="off" label="Besucher-ID" color="blue" class="mt-4 mb-0 blue-text blue-border" v-model="addRequest.query" @keyup.native="searchClient()" v-show="addRequest.clientName === '-'"/>
                <div class="panel-footer pr-5" v-if="addRequest.search_data.length">
                  <ul class="client-search list-group position-absolute w-100 shadow">
                    <a href="#" class="list-group-item" v-for="client in addRequest.search_data" v-bind:key="client.id" @click="getClientData(client)">{{ client.id - 0 }}</a>
                  </ul>
                </div>
                <section class="mt-4" v-show="addRequest.clientName !== '-'">
                  <h5 class="mb-2">Angebotstyp</h5>
                  <mdb-btn-group class="w-100">
                    <mdb-btn block size="lg" color="customblue" @click.native="toggleQuotationMode1" :active="addRequest.activeQuotationMode1" class="gww-button p-2 ml-0">
                      <h6 class="text-capitalize mb-0">Infogespräch</h6>
                    </mdb-btn>
                    <mdb-btn block size="lg" color="customblue" @click.native="toggleQuotationMode2" :active="addRequest.activeQuotationMode2" class="gww-button p-2">
                      <h6 class="text-capitalize mb-0">Artikel</h6>
                    </mdb-btn>
                  </mdb-btn-group>
                </section>
                <section class="mt-4" v-show="addRequest.clientName !== '-'">
                  <h5 class="mb-2">Katalog</h5>
                  <mdb-btn-group class="w-100">
                    <mdb-btn block size="lg" color="customblue" @click.native="toggleCatalogStatus1" :active="addRequest.activeCatalogStatus1" class="gww-button p-2 ml-0">
                      <h6 class="text-capitalize mb-0">Nein</h6>
                    </mdb-btn>
                    <mdb-btn block size="lg" color="customblue" @click.native="toggleCatalogStatus2" :active="addRequest.activeCatalogStatus2" class="gww-button p-2">
                      <h6 class="text-capitalize mb-0">Erhalten</h6>
                    </mdb-btn>
                    <mdb-btn block size="lg" color="customblue" @click.native="toggleCatalogStatus3" :active="addRequest.activeCatalogStatus3" class="gww-button p-2">
                      <h6 class="text-capitalize mb-0">Zusenden</h6>
                    </mdb-btn>
                  </mdb-btn-group>
                </section>
                <section class="mt-4" v-show="addRequest.clientName !== '-'">
                  <h5 class="mb-2">Notizen</h5>
                  <div class="input-group" v-show="addRequest.noteTab == 0">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon">
                        <i class="fas fa-pencil-alt prefix"></i>
                      </span>
                    </div>
                    <textarea class="form-control" rows="2" v-model="addRequest.notePublic"></textarea>
                  </div>
                  <div class="input-group" v-show="addRequest.noteTab == 1">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon">
                        <i class="fas fa-pencil-alt prefix"></i>
                      </span>
                    </div>
                    <textarea class="form-control" rows="2" v-model="addRequest.notePrivate"></textarea>
                  </div>
                  <mdb-btn-group class="w-100 mt-1">
                    <mdb-btn block size="lg" color="customblue" @click.native.prevent="addRequest.noteTab = 0" :active="addRequest.noteTab == 0" class="gww-button p-2 ml-0">
                      <h6 class="text-capitalize mb-0">Notiz Händler</h6>
                    </mdb-btn>
                    <mdb-btn block size="lg" color="customblue" @click.native.prevent="addRequest.noteTab = 1" :active="addRequest.noteTab == 1" class="gww-button p-2">
                      <h6 class="text-capitalize mb-0">Notiz intern</h6>
                    </mdb-btn>
                  </mdb-btn-group>
                </section>
              </mdb-col>
              <mdb-col col="4" style="border-right: 1px solid #ccc;">
                <section v-show="addRequest.clientName !== '-' && addRequest.activeQuotationMode2">
                  <legend><mdb-icon icon="cubes" fas class="blue-text" /> Artikel auswählen</legend>
                  <mdb-datatable
                    id="products-table"
                    ref="productsTable"
                    :data="'https://gww-lf.de/api/add_request_list_products.php'"
                    :tfoot="false"
                    :sorting="false"
                    focus
                    arrows
                    searchPlaceholder="Suche"
                    :searching="true"
                    refresh
                    showingText="Zeige"
                    class="product-data"
                    @selectRow="selectProduct"
                    :pagination="false"
                    scrollY
                    maxHeight="509px"
                  />
                </section>
              </mdb-col>
              <mdb-col col="4">
                <section class="" v-show="addRequest.clientName !== '-' && addRequest.activeQuotationMode2">
                  <legend><mdb-icon icon="cogs" fas class="blue-text" /> Artikel konfigurieren</legend>
                  <mdb-card class="mt-3">
                    <mdb-card-body class="pt-2 pb-2">
                      <mdb-row>
                        <mdb-col col="12" class="d-flex align-items-center">
                          <h5 class="mb-0 dark-grey-text">{{ addRequest.productLabel }}</h5>
                        </mdb-col>
                      </mdb-row>
                    </mdb-card-body>
                    <mdb-card-footer class="pt-2 pb-2 grey-text" v-if="addRequest.productId != 0">
                      Art.-Nr.: {{ addRequest.productItemNumber }}<span v-if="addRequest.productVariant != '' && addRequest.productVariant != '-'">-{{ addRequest.productVariant }}</span>
                    </mdb-card-footer>
                  </mdb-card>
                </section>
                <section class="mt-4" v-show="addRequest.clientName !== '-' && addRequest.activeQuotationMode2">
                  <h5 class="mb-2">Muster</h5>
                  <mdb-btn-group class="w-100">
                    <mdb-btn block size="lg" color="customblue" @click.native="toggleSampleStatus1" :active="addRequest.activeSampleStatus1" class="gww-button p-2 ml-0">
                      <h6 class="text-capitalize mb-0">&nbsp;&nbsp;Nein&nbsp;&nbsp;</h6>
                    </mdb-btn>
                    <mdb-btn block size="lg" color="customblue" @click.native="toggleSampleStatus2" :active="addRequest.activeSampleStatus2" class="gww-button p-2">
                      <h6 class="text-capitalize mb-0">Erhalten</h6>
                    </mdb-btn>
                  </mdb-btn-group>
                  <mdb-btn-group class="w-100">
                    <mdb-btn block size="lg" color="customblue" @click.native="toggleSampleStatus3" :active="addRequest.activeSampleStatus3" class="gww-button p-2 ml-0">
                      <h6 class="text-capitalize mb-0">Mit Berechnung</h6>
                    </mdb-btn>
                    <mdb-btn block size="lg" color="customblue" @click.native="toggleSampleStatus4" :active="addRequest.activeSampleStatus4" class="gww-button p-2">
                      <h6 class="text-capitalize mb-0">Ohne Berechnung</h6>
                    </mdb-btn>
                  </mdb-btn-group>
                </section>
                <section class="mt-4" v-show="addRequest.clientName !== '-' && addRequest.activeQuotationMode2 && addRequest.productId !== 0">
                  <h5 class="mb-2">Werbeanbringungen</h5>
                  <mdb-select
                    outline
                    multiple
                    search
                    icon="edit"
                    far
                    iconClass="gww-blue-text"
                    placeholder="Werbeanbringungen auswählen"
                    searchPlaceholder="Suche"
                    limitPlaceholder="Optionen ausgewählt"
                    resultText="Nichts gefunden!"
                    class="mt-0"
                    :options="addRequest.finishingOptions"
                  />
                </section>
                <section class="mt-4" v-show="addRequest.clientName !== '-' && addRequest.activeQuotationMode2 && (addRequest.productId !== 0 || addRequest.productLabel == 'Sonderanfertigung')">
                  <mdb-row class="mb-3">
                    <mdb-col col="6" class="d-flex align-items-center">
                      <h5 class="mb-2">Preisstaffeln</h5>
                    </mdb-col>
                    <mdb-col col="6">
                      <mdb-dropdown class="float-right" v-show="addRequest.productLabel != 'Sonderanfertigung'">
                        <mdb-dropdown-toggle color="white" slot="toggle">
                          <h6 class="d-inline text-capitalize mb-0">Vorlagen</h6>
                        </mdb-dropdown-toggle>
                        <mdb-dropdown-menu right>
                          <mdb-dropdown-item @click.prevent="loadPriceTablePreset(1)">Eine Staffelmenge</mdb-dropdown-item>
                          <mdb-dropdown-item @click.prevent="loadPriceTablePreset(2)">Drei Staffelmengen</mdb-dropdown-item>
                          <mdb-dropdown-item @click.prevent="loadPriceTablePreset(3)">Alle Staffelmengen</mdb-dropdown-item>
                          <mdb-dropdown-item @click.prevent="loadPriceTablePreset(4)">Eine Staffelmenge mit Menge 1</mdb-dropdown-item>
                        </mdb-dropdown-menu>
                      </mdb-dropdown>
                    </mdb-col>
                  </mdb-row>
                  <span @click="addToPriceTable" class="table-add custom float-left mb-2 mr-2"><a href="#!" class="text-success"><mdb-icon icon="plus"/></a></span>
                  <mdb-table-editable
                    :columns="addRequest.editableColumns"
                    :rows="addRequest.editableRows"
                    ref="priceTable"
                    fixed
                    :sorting="false"
                    class="price-data mb-5"
                    @click.native="tweakTable($event)"
                    @hook:updated="translateTable"
                  />
                </section>
              </mdb-col>
            </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="blue" @click.native="closeAdd">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="add-form" color="blue" :disabled="loading === true">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          <span v-show="addRequest.requestId === 'new'">Erstellen</span>
          <span v-show="addRequest.requestId !== 'new'">Speichern</span>
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: New -->
    <!-- Modal: Request -->
    <mdb-modal
      fullHeight
      position="bottom"
      scrollable
      removeBackdrop
      :show="showModal"
      @close="closeModal(true)"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Anfrage <b>{{ model.id }}</b> <small style="text-transform: lowercase; font-size: 14px; color: #bbb;">vom {{ model.created_date }} um {{ model.created_time }}</small></mdb-modal-title>      
      </mdb-modal-header>
      <mdb-modal-body class="pt-0">
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row class="pt-2 mb-3" style="background-color: #eee;">
            <mdb-col md="4" class="mb-2" style="border-right: 1px solid #aaa;">
              <mdb-tbl borderless sm>
                <mdb-tbl-body>
                  <tr>
                    <td class="icon"><mdb-icon icon="cube" class="blue-text" /></td>
                    <td class="label">Produkt</td>
                    <td class="details"><b>{{ model.product_label }}</b></td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="barcode" class="blue-text" /></td>
                    <td class="label">Artikel-Nr.</td>
                    <td class="details">{{ model.product_item_number_combined }}</td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="eye" class="blue-text" /></td>
                    <td class="label">Muster</td>
                    <td class="details" v-if="model.approved !== '0000-00-00 00:00:00'">
                      <span v-show="this.model.client_sample === 0" style="color: grey; font-weight: bold;">nein</span>
                      <span v-show="this.model.client_sample === 1" style="color: orange; font-weight: bold;">zusenden (mit Berechnung)</span>
                      <span v-show="this.model.client_sample === 2" style="color: orange; font-weight: bold;">zusenden (ohne Berechnung)</span>
                      <span v-show="this.model.client_sample === 3" style="color: green; font-weight: bold;">erhalten</span>
                    </td>
                    <td class="toggle details" v-else>
                      <span v-show="this.model.client_sample === 0" @click="toggleSample(0)" style="color: grey; font-weight: bold;"><mdb-icon icon="retweet" fas class="blue-text" /> nein</span>
                      <span v-show="this.model.client_sample === 1" @click="toggleSample(1)" style="color: orange; font-weight: bold;"><mdb-icon icon="retweet" fas class="blue-text" /> zusenden (mit Berechnung)</span>
                      <span v-show="this.model.client_sample === 2" @click="toggleSample(2)" style="color: orange; font-weight: bold;"><mdb-icon icon="retweet" fas class="blue-text" /> zusenden (ohne Berechnung)</span>
                      <span v-show="this.model.client_sample === 3" @click="toggleSample(3)" style="color: green; font-weight: bold;"><mdb-icon icon="retweet" fas class="blue-text" /> erhalten</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="book-open" class="blue-text" /></td>
                    <td class="label">Katalog</td>
                    <td class="details" v-if="model.approved !== '0000-00-00 00:00:00'">
                      <span v-show="this.model.client_catalog === 0" style="color: grey; font-weight: bold;">nein</span>
                      <span v-show="this.model.client_catalog === 1" style="color: green; font-weight: bold;">erhalten</span>
                      <span v-show="this.model.client_catalog === 2" style="color: orange; font-weight: bold;">zusenden</span>
                    </td>
                    <td class="toggle details" v-else>
                      <span v-show="this.model.client_catalog === 0" @click="toggleCatalog(0)" style="color: grey; font-weight: bold;"><mdb-icon icon="retweet" fas class="blue-text" /> nein</span>
                      <span v-show="this.model.client_catalog === 1" @click="toggleCatalog(1)" style="color: green; font-weight: bold;"><mdb-icon icon="retweet" fas class="blue-text" /> erhalten</span>
                      <span v-show="this.model.client_catalog === 2" @click="toggleCatalog(2)" style="color: orange; font-weight: bold;"><mdb-icon icon="retweet" fas class="blue-text" /> zusenden</span>
                    </td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
            </mdb-col>
            <mdb-col md="4" class="mb-2" style="border-right: 1px solid #aaa;">
              <mdb-tbl borderless sm v-if="this.usergroup === 'supplier' && model.show_client_in_modal === 0">
                <mdb-tbl-body>
                  <tr>
                    <td class="icon"><mdb-icon icon="user-alt" class="blue-text" /></td>
                    <td class="label">Industriekunde</td>
                    <td class="details"><b>{{ model.client_company }} ({{ model.client }})</b></td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="id-card" class="blue-text" /></td>
                    <td class="label">Kontakt</td>
                    <td class="details">{{ model.client_title }} {{ model.client_last_name }}</td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
              <mdb-tbl borderless sm v-if="this.usergroup === 'trader' || model.show_client_in_modal === 1">
                <mdb-tbl-body>
                  <tr>
                    <td class="icon"><mdb-icon icon="user-alt" class="blue-text" /></td>
                    <td class="label">Industriekunde</td>
                    <td class="details"><b>{{ model.client_company }} ({{ model.client }})</b></td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="id-card" class="blue-text" /></td>
                    <td class="label">Kontakt</td>
                    <td class="details">{{ model.client_title }} {{ model.client_last_name }}</td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="phone" class="blue-text" /></td>
                    <td class="label">Telefon</td>
                    <td class="details"><a style="color: blue;" :href="'tel:' + model.client_phone">{{ model.client_phone }}</a></td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="envelope" class="blue-text" /></td>
                    <td class="label">E-Mail</td>
                    <td class="details"><a style="color: blue;" :href="'mailto:' + model.client_email">{{ model.client_email }}</a></td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
            </mdb-col>
            <mdb-col md="4" class="mb-2">
              <mdb-tbl borderless sm v-show="this.usergroup === 'supplier'">
                <mdb-tbl-body>
                  <tr>
                    <td class="icon"><mdb-icon icon="building" class="blue-text" /></td>
                    <td class="label">Händler</td>
                    <td class="details"><b>{{ model.trader_company }}</b></td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="id-card" class="blue-text" /></td>
                    <td class="label">Kontakt</td>
                    <td class="details">{{ model.trader_title }} {{ model.trader_first_name }} {{ model.trader_last_name }}</td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="phone" class="blue-text" /></td>
                    <td class="label">Telefon</td>
                    <td class="details"><a style="color: blue;" :href="'tel:' + model.trader_phone">{{ model.trader_phone }}</a></td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="envelope" class="blue-text" /></td>
                    <td class="label">E-Mail</td>
                    <td class="details"><a style="color: blue;" :href="'mailto:' + model.trader_email">{{ model.trader_email }}</a></td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
              <mdb-tbl borderless sm v-show="this.usergroup === 'trader'">
                <mdb-tbl-body>
                  <tr>
                    <td class="icon"><mdb-icon icon="building" class="blue-text" /></td>
                    <td class="label">Lieferant</td>
                    <td class="details"><b>{{ model.supplier_company }}</b></td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="id-card" class="blue-text" /></td>
                    <td class="label">Kontakt</td>
                    <td class="details">{{ model.supplier_title }} {{ model.supplier_first_name }} {{ model.supplier_last_name }}</td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="phone" class="blue-text" /></td>
                    <td class="label">Telefon</td>
                    <td class="details"><a style="color: blue;" :href="'tel:' + model.supplier_phone">{{ model.supplier_phone }}</a></td>
                  </tr>
                  <tr>
                    <td class="icon"><mdb-icon icon="envelope" class="blue-text" /></td>
                    <td class="label">E-Mail</td>
                    <td class="details"><a style="color: blue;" :href="'mailto:' + model.supplier_email">{{ model.supplier_email }}</a></td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <!--<legend><mdb-icon icon="list-ol" class="blue-text ml-3" /> Preisstaffeln</legend>-->
            <mdb-col md="6" class="mb-3  scroll-prices" style="border-right: 0px solid #aaa;" v-show="this.model.product_label !== 'Infogespräch' && this.usergroup === 'supplier'">
              <section v-for="(bracket, index) in this.model.price_data" :key="bracket.quantity">
                <legend><mdb-icon icon="angle-right" class="blue-text" /> Staffelmenge - {{ index }} Stück</legend>
                <table class="tprices">
                  <thead>
                    <tr class="tlast">
                      <th class="width2-1"><b>Artikel/Veredelung</b></th>
                      <th class="width2-2 text-right"><b>Menge</b></th>
                      <th class="width2-3 text-right"><b>UVP</b></th>
                      <th class="width2-4 text-right"><b>VK</b></th>
                      <!--<th class="width2-5 currency text-right"><b>VK</b></th>-->
                      <th class="width2-6 currency text-right"><b>Summe</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="width2-2">
                        <span>{{ bracket.product_label }}</span>
                      </td>
                      <td class="width2-1 text-right">
                        <b>{{ index }}</b>
                      </td>
                      <td class="width2-3 text-right">
                        <mdb-input
                          label=""
                          type="number"
                          class="mb-0 mt-0"
                          v-model="bracket.product_price_industry"
                          :disabled="model.approved !== '0000-00-00 00:00:00'"
                        />
                      </td>
                      <td class="width2-4 text-right">
                        <mdb-input
                          label=""
                          type="number"
                          class="mb-0 mt-0"
                          v-model="bracket.product_price_trade"
                          :disabled="model.approved !== '0000-00-00 00:00:00'"
                        />
                      </td>
                      <td class="width2-6 text-right">
                        <b>{{ formatPrice(index * bracket.product_price_trade, 2, bracket.supplier_currency) }}</b>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-for="(finishing) in bracket.finishings" :key="finishing.id">
                    <tr>
                      <td class="width2-2">
                        <span>[W] {{ finishing.label }}</span>
                      </td>
                      <td class="width2-1 text-right">
                        <b>{{ finishing.quantity }}</b>
                      </td>
                      <td class="width2-3 text-right">
                        <mdb-input
                          label=""
                          type="number"
                          class="mb-0 mt-0"
                          v-model="finishing.price_industry"
                          :disabled="model.approved !== '0000-00-00 00:00:00'"
                        />
                      </td>
                      <td class="width2-4 text-right">
                        <mdb-input
                          label=""
                          type="number"
                          class="mb-0 mt-0"
                          v-model="finishing.price_trade"
                          :disabled="model.approved !== '0000-00-00 00:00:00'"
                        />
                      </td>
                      <td class="width2-6 text-right">
                        <b>{{ formatPrice(finishing.quantity * finishing.price_trade, 2, bracket.supplier_currency) }}</b>
                      </td>
                    </tr>
                    <tr v-for="(incidental) in finishing.incidentals" :key="incidental.id">
                      <td class="width2-2">
                        <span>[G] {{ incidental.label }}</span>
                      </td>
                      <td class="width2-1 text-right">
                        <b>1</b>
                      </td>
                      <td class="width2-3 text-right">
                        <mdb-input
                          label=""
                          type="number"
                          class="mb-0 mt-0"
                          v-model="model.incidental_data[incidental.id].price_industry"
                          :disabled="model.approved !== '0000-00-00 00:00:00'"
                        />
                      </td>
                      <td class="width2-4 text-right">
                        <mdb-input
                          label=""
                          type="number"
                          class="mb-0 mt-0"
                          v-model="model.incidental_data[incidental.id].price_trade"
                          :disabled="model.approved !== '0000-00-00 00:00:00'"
                        />
                      </td>
                      <td class="width2-6 text-right">
                        <b>{{ formatPrice(1 * incidental.price_trade, 2, bracket.supplier_currency) }}</b>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="tlast">
                      <td class="width-1"><b>Gesamt</b></td>
                      <td class="width-2"></td>
                      <td class="width-3"></td>
                      <td class="width-4"></td>
                      <td class="width-6"><b :id="'total-' + index">{{ calculateTotal(index) }}</b></td>
                    </tr>
                  </tbody>
                </table>
                <hr>
              </section>
            </mdb-col>
            <mdb-col md="6" class="mb-3  scroll-prices" style="border-right: 0px solid #aaa;" v-show="this.model.product_label !== 'Infogespräch' && this.usergroup === 'trader'">
              <section v-for="(bracket, index) in this.model.price_data" :key="bracket.quantity">
                <legend><mdb-icon icon="angle-right" class="blue-text" /> Staffelmenge - {{ index }} Stück</legend>
                <table class="tprices">
                  <thead>
                    <tr class="tlast">
                      <th class="width2-1"><b>Artikel/Veredelung</b></th>
                      <th class="width2-2 text-right"><b>Menge</b></th>
                      <th class="width2-3 text-right"><b>UVP</b></th>
                      <th class="width2-4 text-right"><b>EK</b></th>
                      <th class="width2-5 currency text-right"><b>VK</b></th>
                      <th class="width2-6 currency text-right"><b>Summe</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="width2-2">
                        <span>{{ bracket.product_label }}</span>
                      </td>
                      <td class="width2-1 text-right">
                        <b>{{ index }}</b>
                      </td>
                      <td class="width2-3 text-right">
                        {{ formatPrice(bracket.product_price_industry, 2, bracket.supplier_currency) }}
                      </td>
                      <td class="width2-4 text-right">
                        {{ formatPrice(bracket.product_price_trade, 2, bracket.supplier_currency) }}
                      </td>
                      <td class="width2-5 text-right">
                        <mdb-input
                          label=""
                          type="number"
                          class="mb-0 mt-0"
                          v-model="bracket.product_price_sale"
                          :disabled="model.approved === '0000-00-00 00:00:00'"
                        />
                      </td>
                      <td class="width2-6 text-right">
                        <b>{{ formatPrice(index * bracket.product_price_sale, 2, bracket.trader_currency) }}</b>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-for="(finishing) in bracket.finishings" :key="finishing.id">
                    <tr>
                      <td class="width2-2">
                        <span>[W] {{ finishing.label }}</span>
                      </td>
                      <td class="width2-1 text-right">
                        <b>{{ finishing.quantity }}</b>
                      </td>
                      <td class="width2-3 text-right">
                        {{ formatPrice(finishing.price_industry, 2, bracket.supplier_currency) }}
                      </td>
                      <td class="width2-4 text-right">
                        {{ formatPrice(finishing.price_trade, 2, bracket.supplier_currency) }}
                      </td>
                      <td class="width2-5 text-right">
                        <mdb-input
                          label=""
                          type="number"
                          class="mb-0 mt-0"
                          v-model="finishing.price_sale"
                          :disabled="model.approved === '0000-00-00 00:00:00'"
                        />
                      </td>
                      <td class="width2-6 text-right">
                        <b>{{ formatPrice(finishing.quantity * finishing.price_sale, 2, bracket.trader_currency) }}</b>
                      </td>
                    </tr>
                    <tr v-for="(incidental) in finishing.incidentals" :key="incidental.id">
                      <td class="width2-2">
                        <span>[G] {{ incidental.label }}</span>
                      </td>
                      <td class="width2-1 text-right">
                        <b>1</b>
                      </td>
                      <td class="width2-3 text-right">
                        {{ formatPrice(incidental.price_industry, 2, bracket.supplier_currency) }}
                      </td>
                      <td class="width2-4 text-right">
                        {{ formatPrice(incidental.price_trade, 2, bracket.supplier_currency) }}
                      </td>
                      <td class="width2-5 text-right">
                        <mdb-input
                          label=""
                          type="number"
                          class="mb-0 mt-0"
                          v-model="model.incidental_data[incidental.id].price_sale"
                          :disabled="model.approved === '0000-00-00 00:00:00'"
                        />
                      </td>
                      <td class="width2-6 text-right">
                        <b>{{ formatPrice(1 * incidental.price_sale, 2, bracket.trader_currency) }}</b>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="tlast">
                      <td class="width-1"><b>Gesamt</b></td>
                      <td class="width-2"></td>
                      <td class="width-3"></td>
                      <td class="width-4"></td>
                      <td class="width-5"></td>
                      <td class="width-6"><b :id="'total-' + index">{{ calculateTotalTrader(index) }}</b></td>
                    </tr>
                  </tbody>
                </table>
                <hr>
              </section>
            </mdb-col>
            <mdb-col md="6" class="mb-3" v-if="this.model.product_label !== 'Infogespräch'">
              <mdb-tabs
                :active="0"
                default
                :links="[
                  { text: 'Notizen' },
                  { text: 'Lieferzeiten' },
                  { text: 'Produkt' },
                  { text: 'Werbeanbringungen' },
                  { text: 'Grundkosten' },
                  { text: 'Bilder' }
                ]"
                :transition-duration="0.5"
                transition-style="linear"
                class="mt-1 pt-2"
              >
                <template :slot="'Notizen'">
                  <mdb-container v-if="this.usergroup === 'supplier'">
                    <legend><mdb-icon icon="comment" class="blue-text mt-4" /> Notiz (intern)</legend>
                    <vue-editor
                      id="supplier-note"
                      v-model="model.supplier_note"
                      :editor-toolbar="editorToolbar"
                      :disabled="model.approved !== '0000-00-00 00:00:00'"
                    />
                    <legend><mdb-icon icon="comments" class="blue-text mt-4" /> Notiz für den Händler</legend>
                    <vue-editor
                      id="trader-note"
                      v-model="model.trader_note"
                      :editor-toolbar="editorToolbar"
                      :disabled="model.approved !== '0000-00-00 00:00:00'"
                    />
                  </mdb-container>
                  <mdb-container v-if="this.usergroup === 'trader'">
                    <legend><mdb-icon icon="comment" class="blue-text mt-4" /> Notiz vom Lieferanten</legend>
                    <vue-editor
                      id="supplier-note"
                      v-model="model.trader_note"
                      :editor-toolbar="editorToolbar"
                      disabled
                    />
                    <legend><mdb-icon icon="comments" class="blue-text mt-4" /> Notiz für den Kunden</legend>
                    <vue-editor
                      id="client-note"
                      v-model="model.client_note"
                      :editor-toolbar="editorToolbar"
                      :disabled="model.approved === '0000-00-00 00:00:00'"
                    />
                  </mdb-container>
                </template>
                <template :slot="'Lieferzeiten'">
                  <mdb-container>
                    <table class="tdelivery">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="text-left"><b>Min</b></th>
                          <th class="text-left"><b>Max</b></th>
                          <th class="text-left"><b>Einheit</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-left">
                            Muster
                          </td>
                          <td class="text-right">
                            <mdb-input
                              label=""
                              type="number"
                              class="mb-0 mt-0"
                              v-model="model.delivery_sample_min"
                              :disabled="usergroup === 'trader' || model.approved !== '0000-00-00 00:00:00'"
                            />
                          </td>
                          <td class="text-right">
                            <mdb-input
                              label=""
                              type="number"
                              class="mb-0 mt-0"
                              v-model="model.delivery_sample_max"
                              :disabled="usergroup === 'trader' || model.approved !== '0000-00-00 00:00:00'"
                            />
                          </td>
                          <td class="text-left">
                            <mdb-select v-model="model.delivery_sample_unit" :disabled="usergroup === 'trader' || model.approved !== '0000-00-00 00:00:00'" label="" class="pt-2 mt-0 mb-0 pb-0" />
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            Freigabemuster
                          </td>
                          <td class="text-right">
                            <mdb-input
                              label=""
                              type="number"
                              class="mb-0 mt-0"
                              v-model="model.delivery_prebatch_min"
                              :disabled="usergroup === 'trader' || model.approved !== '0000-00-00 00:00:00'"
                            />
                          </td>
                          <td class="text-right">
                            <mdb-input
                              label=""
                              type="number"
                              class="mb-0 mt-0"
                              v-model="model.delivery_prebatch_max"
                              :disabled="usergroup === 'trader' || model.approved !== '0000-00-00 00:00:00'"
                            />
                          </td>
                          <td class="text-left">
                            <mdb-select v-model="model.delivery_prebatch_unit" :disabled="usergroup === 'trader' || model.approved !== '0000-00-00 00:00:00'" label="" class="pt-2 mt-0 mb-0 pb-0" />
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            Serie
                          </td>
                          <td  class="text-right">
                            <mdb-input
                              label=""
                              type="number"
                              class="mb-0 mt-0"
                              v-model="model.delivery_batch_min"
                              :disabled="usergroup === 'trader' || model.approved !== '0000-00-00 00:00:00'"
                            />
                          </td>
                          <td class="text-right">
                            <mdb-input
                              label=""
                              type="number"
                              class="mb-0 mt-0"
                              v-model="model.delivery_batch_max"
                              :disabled="usergroup === 'trader' || model.approved !== '0000-00-00 00:00:00'"
                            />
                          </td>
                          <td class="text-left">
                            <mdb-select v-model="model.delivery_batch_unit" :disabled="usergroup === 'trader' || model.approved !== '0000-00-00 00:00:00'" label="" class="pt-2 mt-0 mb-0 pb-0" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <legend><mdb-icon icon="comment" class="blue-text mt-4" /> Anmerkung zur Lieferzeit</legend>
                    <vue-editor
                      id="delivery-note"
                      v-model="model.delivery_note"
                      :editor-toolbar="editorToolbar"
                      :disabled="(model.approved !== '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved === '0000-00-00 00:00:00' && usergroup === 'trader')"
                    />
                  </mdb-container>
                </template>
                <template :slot="'Produkt'">
                  <mdb-container>
                    <mdb-input
                      label="Bezeichnung"
                      type="text"
                      class="mb-3"
                      v-model="model.product_label"
                      :disabled="(model.approved !== '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved === '0000-00-00 00:00:00' && usergroup === 'trader')"
                    />
                    <mdb-input
                      label="Artikelnummer"
                      type="text"
                      class="mb-3"
                      v-model="model.product_item_number"
                      :disabled="(model.approved !== '0000-00-00 00:00:00' && usergroup === 'supplier') || usergroup === 'trader'"
                    />
                    <mdb-input
                      label="Variante"
                      type="text"
                      class="mb-3"
                      v-model="model.product_item_variant"
                      :disabled="(model.approved !== '0000-00-00 00:00:00' && usergroup === 'supplier') || usergroup === 'trader'"
                    />
                    <legend><mdb-icon icon="align-justify" class="blue-text mt-4" /> Beschreibung</legend>
                    <vue-editor
                      id="product-description"
                      v-model="model.product_description"
                      :editor-toolbar="editorToolbar"
                      :disabled="(model.approved !== '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved === '0000-00-00 00:00:00' && usergroup === 'trader')"
                    />
                    <legend><mdb-icon icon="comment" class="blue-text mt-4" /> Preisanmerkung</legend>
                    <vue-editor
                      id="price-note"
                      v-model="model.product_price_note"
                      :editor-toolbar="editorToolbar"
                      :disabled="(model.approved !== '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved === '0000-00-00 00:00:00' && usergroup === 'trader')"
                    />
                  </mdb-container>
                </template>
                <template :slot="'Werbeanbringungen'">
                  <mdb-container class="scroll-finishings">
                    <section v-for="(finishing, index) in this.model.finishing_data" :key="finishing.id">
                      <mdb-input
                        label="Bezeichnung"
                        type="text"
                        class="mb-3"
                        v-model="model.finishing_data[index].label"
                        :disabled="(model.approved !== '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved === '0000-00-00 00:00:00' && usergroup === 'trader')"
                      />
                      <legend><mdb-icon icon="align-justify" class="blue-text mt-4" /> Beschreibung</legend>
                      <vue-editor
                        :id="'finishing-description' + model.finishing_data[index].id"
                        class="pb-5"
                        v-model="model.finishing_data[index].description"
                        :editor-toolbar="editorToolbar"
                        :disabled="(model.approved !== '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved === '0000-00-00 00:00:00' && usergroup === 'trader')"
                      />
                    </section>
                  </mdb-container>
                </template>
                <template :slot="'Grundkosten'">
                  <mdb-container class="scroll-finishings">
                    <section v-for="(incidental, index) in this.model.incidental_data" :key="incidental.id">
                      <mdb-input
                        label="Bezeichnung"
                        type="text"
                        class="mb-3"
                        v-model="model.incidental_data[index].label"
                        :disabled="(model.approved !== '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved === '0000-00-00 00:00:00' && usergroup === 'trader')"
                      />
                      <legend><mdb-icon icon="align-justify" class="blue-text mt-4" /> Beschreibung</legend>
                      <vue-editor
                        :id="'incidental-description' + model.incidental_data[index].id"
                        class="pb-5"
                        v-model="model.incidental_data[index].description"
                        :editor-toolbar="editorToolbar"
                        :disabled="(model.approved !== '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved === '0000-00-00 00:00:00' && usergroup === 'trader')"
                      />
                    </section>
                  </mdb-container>
                </template>
                <template :slot="'Bilder'">
                  <mdb-container>
                    <mdb-row>
                      <mdb-col md="4" class="mb-2" style="border-right: 1px solid #aaa;">
                        <mdb-row>
                          <mdb-col col="6" class="">
                            <h5 class="grey-text mb-0 mt-1 align-text-bottom h-100">1. Bild</h5>
                          </mdb-col>
                          <mdb-col col="6" class="">
                            <mdb-btn flat size="sm" darkWaves class="float-right p-2 red-text" v-show="model.image_1 !== 'image_placeholder.svg' && ((model.approved === '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved !== '0000-00-00 00:00:00' && usergroup === 'trader'))" @click="removeImage('image_1')">1. Bild entfernen</mdb-btn>
                          </mdb-col>
                        </mdb-row> 
                        <mdb-row class="p-3">
                          <img class="upload-image mb-3 img-fluid" :src="model.image_1">
                        </mdb-row>
                        <mdb-row class="pl-3 pr-3 pt-0 pb-0">
                          <input id="image-1-upload" type="file" accept=".jpg, .jpeg, .png" @change="handleFileImage1( $event )" v-show="(model.approved === '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved !== '0000-00-00 00:00:00' && usergroup === 'trader')"/>
                        </mdb-row>
                      </mdb-col>
                      <mdb-col md="4" class="mb-2" style="border-right: 1px solid #aaa;">
                        <mdb-row>
                          <mdb-col col="6" class="">
                            <h5 class="grey-text mb-0 mt-1 align-text-bottom h-100">2. Bild</h5>
                          </mdb-col>
                          <mdb-col col="6" class="">
                            <mdb-btn flat size="sm" darkWaves class="float-right p-2 red-text" v-show="model.image_2 !== 'image_placeholder.svg' && ((model.approved === '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved !== '0000-00-00 00:00:00' && usergroup === 'trader'))" @click="removeImage('image_2')">2. Bild entfernen</mdb-btn>
                          </mdb-col>
                        </mdb-row> 
                        <mdb-row class="p-3">
                          <img class="upload-image mb-3 img-fluid" :src="model.image_2">
                        </mdb-row>
                        <mdb-row class="pl-3 pr-3 pt-0 pb-0">
                          <input id="image-2-upload" type="file" accept=".jpg, .jpeg, .png" @change="handleFileImage2( $event )" v-show="(model.approved === '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved !== '0000-00-00 00:00:00' && usergroup === 'trader')"/>
                        </mdb-row>
                      </mdb-col>
                      <mdb-col md="4" class="mb-2">
                        <mdb-row>
                          <mdb-col col="6" class="">
                            <h5 class="grey-text mb-0 mt-1 align-text-bottom h-100">3. Bild</h5>
                          </mdb-col>
                          <mdb-col col="6" class="">
                            <mdb-btn flat size="sm" darkWaves class="float-right p-2 red-text" v-show="model.image_3 !== 'image_placeholder.svg' && ((model.approved === '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved !== '0000-00-00 00:00:00' && usergroup === 'trader'))" @click="removeImage('image_3')">3. Bild entfernen</mdb-btn>
                          </mdb-col>
                        </mdb-row> 
                        <mdb-row class="p-3">
                          <img class="upload-image mb-3 img-fluid" :src="model.image_3">
                        </mdb-row>
                        <mdb-row class="pl-3 pr-3 pt-0 pb-0">
                          <input id="image-3-upload" type="file" accept=".jpg, .jpeg, .png" @change="handleFileImage3( $event )" v-show="(model.approved === '0000-00-00 00:00:00' && usergroup === 'supplier') || (model.approved !== '0000-00-00 00:00:00' && usergroup === 'trader')"/>
                        </mdb-row>
                      </mdb-col>
                    </mdb-row>
                  </mdb-container>
                </template>
              </mdb-tabs>
            </mdb-col>
            <mdb-col md="12" class="mb-3" v-else>
              <mdb-tabs
                :active="0"
                default
                :links="[
                  { text: 'Notizen' }
                ]"
                :transition-duration="0.5"
                transition-style="linear"
                class="mt-1 pt-2"
              >
                <template :slot="'Notizen'">
                  <mdb-container v-if="this.usergroup === 'supplier'" class="float-left">
                    <legend><mdb-icon icon="comment" class="blue-text mt-4" /> Notiz (intern)</legend>
                    <vue-editor
                      id="supplier-note"
                      v-model="model.supplier_note"
                      :editor-toolbar="editorToolbar"
                      :disabled="model.approved !== '0000-00-00 00:00:00'"
                    />
                    <legend><mdb-icon icon="comments" class="blue-text mt-4" /> Notiz für den Händler</legend>
                    <vue-editor
                      id="trader-note"
                      v-model="model.trader_note"
                      :editor-toolbar="editorToolbar"
                      :disabled="model.approved !== '0000-00-00 00:00:00'"
                    />
                  </mdb-container>
                  <mdb-container v-if="this.usergroup === 'trader'" class="float-left">
                    <legend><mdb-icon icon="comment" class="blue-text mt-4" /> Notiz vom Lieferanten</legend>
                    <vue-editor
                      id="supplier-note"
                      v-model="model.trader_note"
                      :editor-toolbar="editorToolbar"
                      disabled
                    />
                    <legend><mdb-icon icon="comments" class="blue-text mt-4" /> Notiz für den Kunden</legend>
                    <vue-editor
                      id="client-note"
                      v-model="model.client_note"
                      :editor-toolbar="editorToolbar"
                      :disabled="model.approved === '0000-00-00 00:00:00'"
                    />
                  </mdb-container>
                </template>
              </mdb-tabs>
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn style="margin-right: auto !important;" outline="orange" v-show="usergroup === 'supplier' && model.approved === '0000-00-00 00:00:00'" @click.native="editRequest()">Neu konfigurieren</mdb-btn>
        <mdb-btn outline="blue" @click.native="closeModal(true)">Schließen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" outline="blue" color="blue" :disabled="loading === true" v-show="(usergroup === 'trader' && model.approved !== '0000-00-00 00:00:00') || (usergroup === 'supplier' && model.approved === '0000-00-00 00:00:00')">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Speichern
        </mdb-btn>
        <mdb-btn color="green" :disabled="loading === true" v-show="usergroup === 'supplier' && model.approved === '0000-00-00 00:00:00'" @click.native="showSaveApprove = true">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Freigeben
        </mdb-btn>
        <mdb-btn color="green" :disabled="loading === true" v-show="usergroup === 'trader' && model.approved !== '0000-00-00 00:00:00' && model.finalized === '0000-00-00 00:00:00'" @click.native="saveFinalize">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Abschließen
        </mdb-btn>
        <mdb-btn outline="blue" :disabled="loading === true" @click.native="previousRecord"><mdb-icon icon="caret-left" class="mr-1"/></mdb-btn>
        <mdb-btn outline="blue" :disabled="loading === true" @click.native="nextRecord"><mdb-icon icon="caret-right" class="mr-1"/></mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Request -->
    <!-- Modal: Approve -->
    <mdb-modal :show="showApprove" @close="showApprove = false">
      <mdb-modal-header color="green">
        <mdb-modal-title class="white-text">Anfragen freigeben</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="check" size="4x" class="green-text ml-3"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die folgenden Anfragen wirklich freigeben?</strong>
            </p>
            <p class="mb-4">
              <strong class="red-text">Achtung: Für den Händler freigegebene Anfragen können nicht mehr verändert oder gelöscht werden.</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.client_company">
                      {{ record.client_company }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="green" @click="showApprove = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="green" :disabled="loading === true" @click.native="approveRecord">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Freigeben
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Approve -->
    <!-- Modal: saveApprove -->
    <mdb-modal :show="showSaveApprove" @close="showSaveApprove = false">
      <mdb-modal-header color="green">
        <mdb-modal-title class="white-text">Anfrage speichern & freigeben</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="check" size="4x" class="green-text ml-3"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die Anfrage nach dem speichern wirklich freigeben?</strong>
            </p>
            <p class="mb-4">
              <strong class="red-text">Achtung: Für den Händler freigegebene Anfragen können nicht mehr verändert oder gelöscht werden.</strong>
            </p>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="green" @click="showSaveApprove = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="green" :disabled="loading === true" @click.native="saveApprove">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Speichern & Freigeben
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: saveApprove -->
    <!-- Modal: Finalize -->
    <mdb-modal :show="showFinalize" @close="showFinalize = false">
      <mdb-modal-header color="green">
        <mdb-modal-title class="white-text">Anfragen abschließen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="check" size="4x" class="green-text ml-3"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die folgenden Anfragen wirklich abschließen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.client_company">
                      {{ record.client_company }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="green" @click="showFinalize = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="green" :disabled="loading === true" @click.native="finalizeRecord">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Abschließen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Finalize -->
    <!-- Modal: Consultant -->
    <mdb-modal :show="showConsultant" @close="showConsultant = false">
      <mdb-modal-header color="blue">
        <mdb-modal-title class="white-text" v-show="usergroup === 'supplier'">Innendienst zuweisen</mdb-modal-title>
        <mdb-modal-title class="white-text" v-show="usergroup === 'trader'">Internen Mitarbeiter zuweisen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="tag" size="4x" class="blue-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die gewählten Anfragen mit folgendem Mitarbeiter verknüpfen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body class="pb-0">
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-select
                    label="Mitarbeiter"
                    class="mt-3"
                    v-model="associates"
                    ref="bulk_consultant"
                    search
                  />
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.id">
                      {{ record.id }} - {{ record.client_company }}
                    </li>
                    <br>
                    <p v-show="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="blue" @click="showConsultant = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" @click.native="consultEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Zuweisen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Consultant -->
    <!-- Modal: Clone -->
    <mdb-modal :show="showClone" @close="showClone = false">
      <mdb-modal-header color="blue">
        <mdb-modal-title class="white-text">Anfrage kopieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="copy" size="4x" class="blue-text ml-3"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Möchten Sie eine Kopie der folgenden Anfrage erstellen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.id">
                      {{ record.id }} - {{ record.client_company }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="blue" @click="showClone = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" @click.native="cloneRecord">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Kopieren
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Clone -->
    <!-- Modal: Return -->
    <mdb-modal :show="showReturn" @close="showReturn = false">
      <mdb-modal-header color="red">
        <mdb-modal-title class="white-text">Anfragen zurücksetzen </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="undo" size="4x" class="red-text ml-3"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die folgenden Anfragen wirklich zurücksetzen?</strong>
            </p>
            <p>Dem Lieferanten wird dadurch eine erneute Bearbeitung, z.B. zur Korrektur ermöglicht.</p>
            <p class="red-text">Ihre bisherigen Änderungen am Angebot werden bei einer erneuten Freigabe des Lieferanten zurückgesetzt. Ihre Notiz an den Kunden bleibt erhalten.</p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.client_company">
                      {{ record.client_company }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showReturn = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="returnRecord">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Zurücksetzen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Return -->
    <!-- Modal: Delete -->
    <mdb-modal :show="showDelete" @close="showDelete = false">
      <mdb-modal-header color="red">
        <mdb-modal-title>Anfragen löschen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="red-text ml-3"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die folgenden Anfragen wirklich löschen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.client_company">
                      {{ record.client_company }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="red" @click="showDelete = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="red" :disabled="loading === true" @click.native="deleteRecord">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Löschen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
    <!-- Modal: exportRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showExportRecords"
      @close="showExportRecords = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Anfragen exportieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-export" fas size="4x" class="blue-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen sie die Art des Exports aus:
            </p>
            <mdb-input type="radio" id="export-option-1" name="exportOptions" radioValue="export_datasheet_exhibitor" v-model="action.export" class="mb-2" :disabled="selectedRecords.length === 0" v-show="usergroup === 'supplier'" label="Ausgewählte Datensätze als Datenblatt (PDF)" />
            <mdb-input type="radio" id="export-option-2" name="exportOptions" radioValue="export_packing_list_exhibitor" v-model="action.export" class="mb-2" :disabled="selectedRecords.length === 0" v-show="usergroup === 'supplier'" label="Ausgewählte Datensätze als Musterpackliste (PDF)" />
            <mdb-input type="radio" id="export-option-3" name="exportOptions" radioValue="export_offer_client" v-model="action.export" class="mb-2" :disabled="selectedRecords.length === 0" v-show="usergroup === 'trader'" label="Ausgewählte Datensätze als Angebot (PDF mit Briefpapier auf 1. Seite)" />
            <mdb-input type="radio" id="export-option-4" name="exportOptions" radioValue="export_offer_client_all" v-model="action.export" class="mb-2" :disabled="selectedRecords.length === 0" v-show="usergroup === 'trader'" label="Ausgewählte Datensätze als Angebot (PDF mit Briefpapier auf jeder Seite)" />
            <mdb-input type="radio" id="export-option-5" name="exportOptions" radioValue="export_offer_client_clean" v-model="action.export" class="mb-2" :disabled="selectedRecords.length === 0" v-show="usergroup === 'trader'" label="Ausgewählte Datensätze als Angebot (PDF ohne Briefpapier)" />
            <mdb-input type="radio" id="export-option-6" name="exportOptions" radioValue="export_datasheet_client" v-model="action.export" class="mb-2" :disabled="selectedRecords.length === 0" v-show="usergroup === 'trader'" label="Ausgewählte Datensätze als Datenblatt [für Kunden] (PDF)" />
            <mdb-input type="radio" id="export-option-7" name="exportOptions" radioValue="export_datasheet_trader" v-model="action.export" class="mb-2" :disabled="selectedRecords.length === 0" v-show="usergroup === 'trader'" label="Ausgewählte Datensätze als Datenblatt [intern] (PDF)" />
            <mdb-input type="radio" id="export-option-8" name="exportOptions" radioValue="export_packing_list_trader" v-model="action.export" class="mb-2" :disabled="selectedRecords.length === 0" v-show="usergroup === 'trader'" label="Ausgewählte Datensätze als Musterpackliste (PDF)" />
            <mdb-input type="radio" id="export-option-9" name="exportOptions" radioValue="export_delivery_note_trader" v-model="action.export" class="mb-2" :disabled="selectedRecords.length === 0" v-show="usergroup === 'trader'" label="Ausgewählte Datensätze als Lieferschein für Muster und Kataloge (PDF)" />
            <hr>
            <mdb-input type="radio" id="export-option-10" name="exportOptions" radioValue="export_gww" v-model="action.export" class="mb-2" :disabled="selectedRecords.length === 0" v-show="usergroup === 'trader'" label="Ausgewählte Datensätze als CDH-Format (GWW)" />
            <mdb-input type="radio" id="export-option-11" name="exportOptions" radioValue="export_wex" v-model="action.export" class="mb-2" :disabled="selectedRecords.length !== 1" v-show="usergroup === 'trader'" label="Ausgewählter Datensatz als CDH-Format (WEX)" />
            <mdb-input type="radio" id="export-option-12" name="exportOptions" radioValue="export_ks1" v-model="action.export" class="mb-2" :disabled="selectedRecords.length === 0" v-show="usergroup === 'trader'" label="Ausgewählte Datensätze als CDH-Format (KS1)" />
            <hr>
            <mdb-input type="radio" id="export-option-13" name="exportOptions" radioValue="export_table" v-model="action.export" class="mb-2" label="Alle Datensätze als Tabelle (XLSX)" />
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.client_company">
                      {{ record.client_company }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="blue" @click="showExportRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="blue" :disabled="loading === true" @click.native="exportRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Exportieren
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: exportRecords -->
  </mdb-container>
</template>

<script>
function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  } else if (document.selection) {
    document.selection.empty()
  }
}

import Vue from 'vue'
import { bus } from '../../main'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Edit, Toolbar, Resize, Search, Sort, Group } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbBtnGroup,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
  mdbCardFooter,
  mdbSelect,
  mdbTbl,
  mdbTblBody,
  mdbTabs,
  mdbDatatable,
  mdbDropdown,
  mdbDropdownToggle,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbTableEditable
} from 'mdbvue'
import { VueEditor } from "vue2-editor"

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

Vue.use(GridPlugin)

export default {
  name: 'Requests',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbBtnGroup,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbCardFooter,
    mdbSelect,
    mdbTbl,
    mdbTblBody,
    mdbTabs,
    mdbDatatable,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbTableEditable,
    VueEditor
  },
  data () {
    return {
      loading: false,
      usergroup: localStorage.getItem('usergroup'),
      model: [],
      associates: [],
      files: {
        image_1: [],
        image_2: [],
        image_3: []
      },
      action: {
        selection: [],
        export: ''
      },
      selectedRecords: [],
      lastRow: 0,
      showAdd: false,
      showModal: false,
      modalEdit: false,
      showApprove: false,
      showSaveApprove: false,
      showFinalize: false,
      showConsultant: false,
      showClone: false,
      showReturn: false,
      showDelete: false,
      showExportRecords: false,
      wasValidated: false,
      dataSource: new DataManager({
        url: Vue.prototype.$serverUrl + 'api/list_requests.php',
        adaptor: new UrlAdaptor,
        crossDomain: true
      }),
      toolbarSupplier: [
        { text: 'Neue Anfrage', tooltipText: 'Anfrage erstellen', prefixIcon: 'e-add', id: 'addRecord' },
        { type: 'Separator' },
        { text: 'Bearbeiten', tooltipText: 'Die ausgewählte Anfrage bearbeiten', prefixIcon: 'e-edit', id: 'editRecord' },
        { text: 'Freigeben', tooltipText: 'Anfragen freigeben', prefixIcon: 'e-icon-check-box-2', id: 'approveRecord' },
        { type: 'Separator' },
        { text: 'Innendienst', tooltipText: 'Anfragen einen Mitarbeiter zuweisen', prefixIcon: 'e-icon-resource', id: 'consultRecord' },
        { text: 'Kopieren', tooltipText: 'Anfragen kopieren', prefixIcon: 'e-icon-copy', id: 'cloneRecord' },
        { text: 'Löschen', tooltipText: 'Anfragen löschen', prefixIcon: 'e-delete-4', id: 'deleteRecord' },
        { type: 'Separator' },
        { text: 'Export', tooltipText: 'Anfragen exportieren', prefixIcon: 'e-icon-export', id: 'exportRecords' },
        'Search'
      ],
      toolbarTrader: [
        { text: 'Bearbeiten', tooltipText: 'Die ausgewählte Anfrage bearbeiten', prefixIcon: 'e-edit', id: 'editRecord' },
        { text: 'Abschließen', tooltipText: 'Anfragen abschließen', prefixIcon: 'e-icon-check-box', id: 'finalizeRecord' },
        { type: 'Separator' },
        { text: 'MA Int.', tooltipText: 'Anfragen einen Mitarbeiter zuweisen', prefixIcon: 'e-icon-resource', id: 'consultRecord' },
        { text: 'Zurücksetzen', tooltipText: 'Anfragen zurücksetzen', prefixIcon: 'e-icon-redo-2', id: 'returnRecord' },
        { type: 'Separator' },
        { text: 'Export', tooltipText: 'Anfragen exportieren', prefixIcon: 'e-icon-export', id: 'exportRecords' },
        'Search'
      ],
      filterOptions: {
        type: 'Menu'
      },
      filter: {
        type: 'CheckBox'
      },
      editorToolbar: [
        ["bold", "italic", "underline"]
      ],
      sortSettings: {
        columns: [
          {
            field: 'created',
            direction: 'Descending'
          }
        ]
      },
      groupSettings: { 
        captionTemplate: "<div>${format(data)}</div>",
        columns: []
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        allowEditOnDblClick: false
      },
      selectionOptions: {
        checkboxMode: 'Default'
      },
      clientColumnAttributes : {
        class: 'label-column'
      },
      statusColumnAttributes : {
        class: 'status-column'
      },
      addRequest: {
        requestId: 'new',
        selectId: 0,
        activeQuotationMode1: true,
        activeQuotationMode2: false,
        activeCatalogStatus1: true,
        activeCatalogStatus2: false,
        activeCatalogStatus3: false,
        activeSampleStatus1: true,
        activeSampleStatus2: false,
        activeSampleStatus3: false,
        activeSampleStatus4: false,
        query: '',
        search_data: [],
        clientId: '-',
        clientCompany: '-',
        clientName: '-',
        traderCompany: '-',
        productList: [],
        productId: 0,
        productLabel: 'Infogespräch',
        productItemNumber: '-',
        productVariant: '',
        finishingOptions: [],
        priceOptions: {},
        noteTab: 0,
        notePublic: '',
        notePrivate: '',
        editableColumns: [
          {
            label: 'Staffelmenge',
            field: 'quote'
          }
        ],
        editableRows: []
      }
    }
  },
  created() {
    bus.$on('eventChange', this.eventChange)
  },
  beforeDestroy() {
    bus.$off('eventChange', this.eventChange)
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    })
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    })
    this.resetModel()
    axios.get('list_consultants.php?for=request').then(response => {
      this.associates = response.data
    })
    axios.get('add_request_list_products.php').then(response => {
      this.addRequest.productList = response.data
    })
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['approveRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['finalizeRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['consultRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['cloneRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['returnRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
  },
  watch: {
    selectedRecords: {
      handler: function () {
        if (this.selectedRecords.length === 0) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['approveRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['finalizeRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['consultRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['returnRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['approveRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['finalizeRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['consultRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['returnRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], true)
        }
        if (this.selectedRecords.length === 1) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['cloneRecord'], true)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['cloneRecord'], false)
        }
      },
      deep: true
    },
    showExportRecords: {
      handler: function () {
        if (this.showExportRecords === true) {
          if (this.selectedRecords.length === 0) {
            this.action.export = 'export_table'
            console.log(this.action.export)
          } else if (this.usergroup === 'supplier') {
            this.action.export = 'export_datasheet_exhibitor'
          } else {
            this.action.export = 'export_offer_client'
          }
        }
      }
    }
  },
  methods: {
    eventChange: function () {
      this.$refs.grid.refresh()
    },
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['created', 'id', 'supplier_associate', 'trader_associate', 'client_associate', 'trader_company', 'client', 'client_catalog', 'client_sample'])
      if (this.lastRow !== 0) {
        this.$refs.grid.selectRow(this.lastRow)
        this.lastRow = 0
      }
    },
    actionComplete: function (e) {
      if (e.requestType === 'grouping' || e.requestType === 'searchingss') {
        this.$refs.grid.ej2Instances.groupModule.collapseAll()
      }
    },
    queryCellInfo: function (args) {
      if (args.column.field === 'approved') {
        if (args.data['approved'] === 'nicht freigegeben') {
          args.cell.classList.add('status-circle', 'status-red')
        } else if (args.data['approved'] === 'freigegeben') {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }
      if (args.column.field === 'finalized') {
        if (args.data['finalized'] === 'nicht abgeschlossen') {
          args.cell.classList.add('status-circle', 'status-grey')
        } else if (args.data['finalized'] === 'abgeschlossen') {
          args.cell.classList.add('status-circle', 'status-green')
        }
        args.cell.textContent = ''
      }
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addRecord') {
        axios.get('add_request_list_products.php').then(response => {
          this.addRequest.productList = response.data
        })
        if (this.selectedRecords.length > 0) {
          this.addRequest.query = this.selectedRecords[0].client
          axios.post('add_request_search_client.php', {
            query: this.addRequest.query
          }).then(response => {
            this.addRequest.query = response.data[0].id - 0
            this.addRequest.clientId = response.data[0].id - 0
            this.addRequest.clientCompany = response.data[0].company
            this.addRequest.clientName = response.data[0].first_name + ' ' + response.data[0].last_name
            this.addRequest.traderCompany = response.data[0].referral
            this.showAdd = true
          })
        } else {
          this.showAdd = true
        }
      }
      if (args.item.id === 'editRecord') {
        axios.get('get_request.php?id=' + this.selectedRecords[0].id).then(response => {
          this.model = response.data
          this.showModal = true
          this.modalEdit = true
        })
      }
      if (args.item.id === 'approveRecord') {
        this.action.selection = []
        for (var i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showApprove = true
      }
      if (args.item.id === 'finalizeRecord') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showFinalize = true
      }
      if (args.item.id === 'consultRecord') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showConsultant = true
      }
      if (args.item.id === 'cloneRecord') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showClone = true
      }
      if (args.item.id === 'returnRecord') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showReturn = true
      }
      if (args.item.id === 'deleteRecord') {
        this.action.selection = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.action.selection.push(this.selectedRecords[i].id)
        }
        this.showDelete = true
      }
      if (args.item.id === 'exportRecords') {
        this.showExportRecords = true
      }
    },
    onRowSelected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    onRowDeselected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    recordDoubleClick: function(row) {
      clearSelection()
      this.$refs.grid.clearRowSelection()
      this.$refs.grid.selectRow(row.rowIndex)
      axios.get('get_request.php?id=' + row.rowData.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    nextRecord () {
      //this.loading = true
      this.closeModal()
      var rowIndex = this.$refs.grid.getSelectedRowIndexes()
      this.$refs.grid.selectRow(rowIndex[0] + 1)
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
      axios.get('get_request.php?id=' + this.selectedRecords[0].id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
        //this.loading = false
      })
    },
    previousRecord () {
      //this.loading = true
      this.closeModal()
      var rowIndex = this.$refs.grid.getSelectedRowIndexes()
      this.$refs.grid.selectRow(rowIndex[0] - 1)
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
      axios.get('get_request.php?id=' + this.selectedRecords[0].id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
        //this.loading = false
      })
    },
    closeModal (refresh = false) {
      this.showModal = false
      this.resetModel()
      if (refresh === true) {
        this.$refs.grid.refresh()
      }
    },
    closeAdd () {
      this.showAdd = false
      this.resetModel()
    },
    saveApprove() {
      this.model.mode = 'approve'
      this.showSaveApprove = false
      this.model.approved = 'freigegeben'
      this.saveModal()
    },
    saveFinalize() {
      this.model.mode = 'finalize'
      this.model.finalized = 'abgeschlossen'
      this.saveModal()
    },
    saveModal() {
      //this.wasValidated = true
      //event.target.classList.add('was-validated')
      if (this.model.id !== '') {
        this.loading = true
        let formData = new FormData()
        formData.append('request_id', this.model.id)
        formData.append('request_1', this.files.image_1)
        if (this.model.image_1 === 'image_placeholder.svg') {
          formData.append('delete_request_1', true)
        } else {
          formData.append('delete_request_1', false)
        }
        formData.append('request_2', this.files.image_2)
        if (this.model.image_2 === 'image_placeholder.svg') {
          formData.append('delete_request_2', true)
        } else {
          formData.append('delete_request_2', false)
        }
        formData.append('request_3', this.files.image_3)
        if (this.model.image_3 === 'image_placeholder.svg') {
          formData.append('delete_request_3', true)
        } else {
          formData.append('delete_request_3', false)
        }
        axios.post( 'import_images.php',
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
          if (response.data.success === true) {
            this.files.image_1 = []
            this.files.image_2 = []
            this.files.image_3 = []
          }
        }).catch(error => {
          this.loading = false
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })

        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_request.php'
        } else {
          url = 'add_request.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          this.loading = false
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            if (this.modalEdit === true) {
              // var rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(this.model.id.toString())
              // this.$refs.grid.updateRow(rowIndex, this.model)
              // this.lastRow = rowIndex
            } else {
              // this.$refs.grid.refresh()
            }
            //this.showModal = false
            this.resetModel()
          }
        }).catch(error => {
          this.loading = false
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    handleFileImage1( event ){
      this.files.image_1 = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.files.image_1)
      reader.onload = e =>{
          this.model.image_1 = e.target.result
      }
    },
    handleFileImage2( event ){
      this.files.image_2 = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.files.image_2)
      reader.onload = e =>{
          this.model.image_2 = e.target.result
      }
    },
    handleFileImage3( event ){
      this.files.image_3 = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.files.image_3)
      reader.onload = e =>{
          this.model.image_3 = e.target.result
      }
    },
    removeImage (type) {
      if (type === 'image_1') {
        this.model.image_1 = 'image_placeholder.svg'
        this.files.image_1 = []
      }
      if (type === 'image_2') {
        this.model.image_2 = 'image_placeholder.svg' 
        this.files.image_2 = []
      }
      if (type === 'image_3') {
        this.model.image_3 = 'image_placeholder.svg' 
        this.files.image_3 = []
      }
    },
    approveRecord () {
      this.loading = true
      var url = 'approve_request.php'
      axios({
        method: 'post',
        url: url,
        data: this.action.selection
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showApprove = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    finalizeRecord () {
      this.loading = true
      var url = 'finalize_request.php'
      axios({
        method: 'post',
        url: url,
        data: this.action.selection
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showFinalize = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    consultEntry () {
      this.loading = true
      var url = 'consult_request.php'
      const payload = {
        ids: this.action.selection,
        consultant: this.associates
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showConsultant = false
          this.resetModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    cloneRecord () {
      this.loading = true
      var url = 'clone_request.php'
      axios({
        method: 'post',
        url: url,
        data: this.action.selection
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showClone = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    returnRecord () {
      this.loading = true
      var url = 'return_request.php'
      axios({
        method: 'post',
        url: url,
        data: this.action.selection
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showReturn = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    deleteRecord () {
      this.loading = true
      var url = 'delete_request.php'
      axios({
        method: 'post',
        url: url,
        data: this.action.selection
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDelete = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    exportRecords () {
      var ids = ''
      this.selectedRecords.forEach(function (item) {
        ids += item.id + ','
      })
      ids = ids.slice(0, -1)
      var url = ''
      if (this.action.export === 'export_gww') {
        url = Vue.prototype.$serverUrl + 'api/export_requests_gww.php?id=' + ids
      } else if (this.action.export === 'export_wex') {
        url = Vue.prototype.$serverUrl + 'api/export_requests_wex.php?id=' + ids
      } else if (this.action.export === 'export_ks1') {
        url = Vue.prototype.$serverUrl + 'api/export_requests_ks1.php?id=' + ids
      } else {
        url = Vue.prototype.$serverUrl + 'api/export_requests.php?export=' + this.action.export + '&id=' + ids
      }
      window.open(url)
    },
    toggleSample(value) {
      value++
      if (value > 3) {
        value = 0
      }
      this.model.client_sample = value
    },
    toggleCatalog(value) {
      value++
      if (value > 2) {
        value = 0
      }
      this.model.client_catalog = value
    },
    formatPrice(value, decimals, currency = 'EUR') {
        let val = (value/1).toFixed(decimals).replace('.', ',')
        return val.toString() + ' ' + currency
    },
    calculateTotal(bracket) {
      var data = this.model.price_data
      var total = 0
      total += bracket * data[bracket].product_price_trade
      for (var finishing in data[bracket].finishings) {
        total += data[bracket].finishings[finishing].quantity * data[bracket].finishings[finishing].price_trade
        for (var incidental in data[bracket].finishings[finishing].incidentals) {
          total += 1 * this.model.incidental_data[incidental].price_trade
        }
      }
      return this.formatPrice(total, 2, this.model.supplier_currency)
    },
    calculateTotalTrader(bracket) {
      var data = this.model.price_data
      var total = 0
      total += bracket * data[bracket].product_price_sale
      for (var finishing in data[bracket].finishings) {
        total += data[bracket].finishings[finishing].quantity * data[bracket].finishings[finishing].price_sale
        for (var incidental in data[bracket].finishings[finishing].incidentals) {
          total += 1 * this.model.incidental_data[incidental].price_sale
        }
      }
      return this.formatPrice(total, 2, this.model.trader_currency)
    },
    resetModel () {
      if (this.selectedRecords === 0) {
        this.action.export = 'export_table'
      } else if (this.usergroup === 'supplier') {
        this.action.export = 'export_datasheet_exhibitor'
      } else {
        this.action.export = 'export_offer_client'
      }
      this.addRequest.requestId = 'new'
      this.addRequest.selectId = 0
      this.addRequest.query = ''
      this.addRequest.search_data = []
      this.addRequest.clientId = '-'
      this.addRequest.clientCompany = '-'
      this.addRequest.clientName = '-'
      this.addRequest.traderCompany = '-'
      this.addRequest.activeQuotationMode1 = true
      this.addRequest.activeQuotationMode2 = false
      this.addRequest.activeSampleStatus1 = true
      this.addRequest.activeSampleStatus2 = false
      this.addRequest.activeSampleStatus3 = false
      this.addRequest.activeSampleStatus4 = false
      this.addRequest.activeCatalogStatus1 = true
      this.addRequest.activeCatalogStatus2 = false
      this.addRequest.activeCatalogStatus3 = false
      this.noteTab = 0
      this.addRequest.notePublic = ''
      this.addRequest.notePrivate = ''
      this.addRequest.productList = []
      this.addRequest.productId = 0
      this.addRequest.productLabel = 'Infogespräch'
      this.addRequest.productItemNumber = '-'
      this.addRequest.productVariant = ''
      this.addRequest.finishingOptions = []
      this.addRequest.priceOptions = {}
      this.addRequest.editableRows = []
    },
    toggleQuotationMode1 () {
      this.addRequest.activeQuotationMode1 = true
      this.addRequest.activeQuotationMode2 = false
      this.addRequest.productId = 0
      this.addRequest.productLabel = 'Infogespräch'
      this.addRequest.productItemNumber = '-'
      this.addRequest.productVariant = ''
      this.addRequest.finishingOptions = []
      this.addRequest.priceOptions = {}
    },
    toggleQuotationMode2 () {
      if (this.addRequest.productId === 0) {
        this.addRequest.activeQuotationMode1 = false
        this.addRequest.activeQuotationMode2 = true
        this.addRequest.productId = 0
        this.addRequest.productLabel = 'Sonderanfertigung'
        this.addRequest.productItemNumber = '-'
        this.addRequest.productVariant = ''
      }
    },
    toggleCatalogStatus1 () {
      this.addRequest.activeCatalogStatus1 = true
      this.addRequest.activeCatalogStatus2 = false
      this.addRequest.activeCatalogStatus3 = false
    },
    toggleCatalogStatus2 () {
      this.addRequest.activeCatalogStatus1 = false
      this.addRequest.activeCatalogStatus2 = true
      this.addRequest.activeCatalogStatus3 = false
    },
    toggleCatalogStatus3 () {
      this.addRequest.activeCatalogStatus1 = false
      this.addRequest.activeCatalogStatus2 = false
      this.addRequest.activeCatalogStatus3 = true
    },
    toggleSampleStatus1 () {
      this.addRequest.activeSampleStatus1 = true
      this.addRequest.activeSampleStatus2 = false
      this.addRequest.activeSampleStatus3 = false
      this.addRequest.activeSampleStatus4 = false
    },
    toggleSampleStatus2 () {
      this.addRequest.activeSampleStatus1 = false
      this.addRequest.activeSampleStatus2 = true
      this.addRequest.activeSampleStatus3 = false
      this.addRequest.activeSampleStatus4 = false
    },
    toggleSampleStatus3 () {
      this.addRequest.activeSampleStatus1 = false
      this.addRequest.activeSampleStatus2 = false
      this.addRequest.activeSampleStatus3 = true
      this.addRequest.activeSampleStatus4 = false
    },
    toggleSampleStatus4 () {
      this.addRequest.activeSampleStatus1 = false
      this.addRequest.activeSampleStatus2 = false
      this.addRequest.activeSampleStatus3 = false
      this.addRequest.activeSampleStatus4 = true
    },
    searchClient: function () {
      this.addRequest.search_data = []
      axios.post('add_request_search_client.php', {
        query: this.addRequest.query
      }).then(response => {
        this.addRequest.search_data = response.data
      })
    },
    getClientData: function (client) {
      this.addRequest.query = client.id - 0
      this.addRequest.clientId = client.id - 0
      this.addRequest.clientCompany = client.company
      this.addRequest.clientName = client.first_name + ' ' + client.last_name
      this.addRequest.traderCompany = client.referral
      this.addRequest.search_data = []
    },
    searchClientAlt: function () {
      this.addRequest.search_data = []
      axios.post('add_request_search_client.php', {
        query: this.addRequest.query
      }).then(response => {
        this.addRequest.query = response.data[0].id - 0
        this.addRequest.clientId = response.data[0].id - 0
        this.addRequest.clientCompany = response.data[0].company
        this.addRequest.clientName = response.data[0].first_name + ' ' + response.data[0].last_name
        this.addRequest.traderCompany = response.data[0].referral
        this.addRequest.search_data = []
      })
    },
    selectProduct: function (index) {
      this.addRequest.selectId = this.addRequest.productList.rows[index].id
      this.loadProduct()
    },
    loadProduct: function () {
      this.addRequest.productId = this.addRequest.selectId
      if (this.addRequest.productId === '-') {
        this.addRequest.productId = 0
      }
      axios.get('add_request_get_product.php?id=' + this.addRequest.productId).then(response => {
        this.addRequest.productItemNumber = response.data.item_number
        this.addRequest.productVariant = response.data.item_variant
        this.addRequest.productLabel = response.data.label
        axios.get('add_request_get_finishings.php?id=' + this.addRequest.productId).then(response => {
          this.addRequest.finishingOptions = response.data
          // this.addRequest.updateComponent = true
          axios.get('add_request_get_prices.php?id=' + this.addRequest.productId).then(response => {
            this.addRequest.priceOptions = response.data
            this.addRequest.editableRows = [{quote: response.data.first}]
            // this.addRequest.updateComponent = false
          })
        })
      })
    },
    loadProductAlt: function () {
      this.addRequest.productId = this.addRequest.selectId
      if (this.addRequest.productId === '-') {
        this.addRequest.productId = 0
      }
      axios.get('add_request_get_product.php?id=' + this.addRequest.productId).then(response => {
        this.addRequest.productItemNumber = response.data.item_number
        this.addRequest.productVariant = response.data.item_variant
        this.addRequest.productLabel = response.data.label
        axios.get('add_request_get_finishings.php?id=' + this.addRequest.productId).then(response => {
          this.addRequest.finishingOptions = response.data
          var finishing_temp = this.model.finishing.split('|')
          for (var index in this.addRequest.finishingOptions) {
            if (finishing_temp.includes(this.addRequest.finishingOptions[index].value)) {
              this.addRequest.finishingOptions[index].selected = true
            }
          }
          // this.addRequest.updateComponent = true
          axios.get('add_request_get_prices.php?id=' + this.addRequest.productId).then(response => {
            this.addRequest.priceOptions = response.data
            // this.addRequest.editableRows = [{quote: response.data.first}]
            for (const [key] of Object.entries(this.model.price_data)) {
              const newRow = {
                quote: key
              }
              this.addRequest.editableRows.push(newRow)
            }
            // console.log(this.addRequest.editableRows)
            // this.model.price_data
            // this.addRequest.updateComponent = false
          })
        })
      })
    },
    addToPriceTable () {
      const newRow = {
        quote: 'Wert eingeben ...'
      }
      this.addRequest.editableRows.push(newRow)
      var table = this.$refs.priceTable.$el
      var lastValue = table.querySelector('tbody tr:last-of-type td:first-of-type')
      lastValue.scrollIntoView()
      // var table = this.$refs.priceTable.$el
      // setTimeout(function () {
      //   var lastValue = table.querySelector('tbody tr:last-of-type td:first-of-type')
      //   lastValue.focus()
      //   var range = document.createRange()
      //   range.selectNodeContents(lastValue)
      //   var sel = window.getSelection()
      //   sel.removeAllRanges()
      //   sel.addRange(range)
      //   lastValue.scrollIntoView()
      // }, 100)
    },
    loadPriceTablePreset (value) {
      this.addRequest.editableRows = []
      var i = 1
      if (value === 1) {
        for (const row of this.addRequest.priceOptions.rows) {
          const newRow = {
            quote: row.quote
          }
          if (i <= 1) {
            this.addRequest.editableRows.push(newRow)
          }
          i++
        }
      } else if (value === 2) {
        for (const row of this.addRequest.priceOptions.rows) {
          const newRow = {
            quote: row.quote
          }
          if (i <= 3) {
            this.addRequest.editableRows.push(newRow)
          }
          i++
        }
      } else if (value === 3) {
        for (const row of this.addRequest.priceOptions.rows) {
          const newRow = {
            quote: row.quote
          }
          this.addRequest.editableRows.push(newRow)
        }
      } else if (value === 4) {
        const newRow = {
          quote: 1
        }
        this.addRequest.editableRows.push(newRow)
      }
    },
    translateTable () {
      var table = this.$refs.priceTable.$el
      var header = table.querySelector('thead th:last-of-type')
      header.innerHTML = ''
      var buttons = table.querySelectorAll('tbody tr td:last-of-type button')
      for (var i = 0; i < buttons.length; ++i) {
        buttons[i].style.display = 'block'
        buttons[i].innerHTML = '<i class="fas prefix fa-times"></i>'
      }
      var values = table.querySelectorAll('tbody tr td:first-of-type')
      for (i = 0; i < values.length; ++i) {
        values[i].setAttribute('inputmode', 'decimal')
        values[i].addEventListener('keydown', function (e) {
          var key = e.keyCode || e.charCode
          if (key === 13) {
            e.preventDefault()
            this.blur()
            window.getSelection().removeAllRanges()
          }
        })
        values[i].addEventListener('keyup', function (e) {
          var key = e.keyCode || e.charCode
          if (key === 9) {
            var range = document.createRange()
            range.selectNodeContents(e.target)
            var sel = window.getSelection()
            sel.removeAllRanges()
            sel.addRange(range)
          }
        })
        var htmlstring = values[i].innerHTML
        htmlstring = (htmlstring.trim) ? htmlstring.trim() : htmlstring.replace(/^\s+/, '')
        if (htmlstring === 'quote') {
          values[i].innerHTML = 'Wert eingeben ...'
        }
      }
    },
    tweakTable (event) {
      var range = document.createRange()
      range.selectNodeContents(event.target)
      var sel = window.getSelection()
      sel.removeAllRanges()
      if (event.target.nodeName === 'TD') {
        sel.addRange(range)
      }
    },
    editRequest: function () {
      axios.get('add_request_list_products.php').then(response => {
        this.addRequest.productList = response.data
        this.addRequest.requestId = this.model.id
        this.addRequest.query = this.model.client
        this.searchClientAlt()
        if (this.model.client_consultation === 0) {
          this.toggleQuotationMode2()
          this.addRequest.selectId = this.model.product
          this.loadProductAlt()
        } else if (this.model.client_consultation === 1) {
          this.toggleQuotationMode1()
        }
        if (this.model.client_catalog === 0) {
          this.toggleCatalogStatus1()
        } else if (this.model.client_catalog === 1) {
          this.toggleCatalogStatus2()
        } else if (this.model.client_catalog === 2) {
          this.toggleCatalogStatus3()
        }
        if (this.model.client_sample === 0) {
          this.toggleSampleStatus1()
        } else if (this.model.client_sample === 1) {
          this.toggleSampleStatus3()
        } else if (this.model.client_sample === 2) {
          this.toggleSampleStatus4()
        } else if (this.model.client_sample === 3) {
          this.toggleSampleStatus2()
        }
        this.addRequest.notePublic = this.model.trader_note
        this.addRequest.notePrivate = this.model.supplier_note
        //this.addRequest.finishingOptions = []
        //this.addRequest.priceOptions = {}
        //this.addRequest.editableRows = []

        this.showModal = false
        this.showAdd = true
      })
    },
    submitRequest: function () {
      if (this.addRequest.clientId === 0 || this.addRequest.clientId === '-' || this.addRequest.clientId === '') {
        if (this.addRequest.requestId === 'new' && this.addRequest.selectId === 0) {
          alert('Es wurde kein Kunde ausgewählt!')
        }
      } else {
        this.payload = {}
        this.payload['request_id'] = this.addRequest.requestId
        this.payload['quantity'] = ''
        if (this.$refs.priceTable) {
          for (let i = 0; i < this.$refs.priceTable._data.mutableRows.length; i++) {
            if (!isNaN(this.$refs.priceTable._data.mutableRows[i].quote)) {
              this.payload['quantity'] += this.$refs.priceTable._data.mutableRows[i].quote + '|'
            }
          }
          this.payload['quantity'] = this.payload['quantity'].substring(0, this.payload['quantity'].length - 1)
        }
        if (this.addRequest.productLabel !== 'Infogespräch' && this.payload['quantity'] === '') {
          alert('Es wurde keine gültigen Staffelmengen angegeben!')
        } else {
          this.payload['created'] = new Date().toISOString().slice(0, 19).replace('T', ' ')
          // this.payload['associate'] = this.associate
          this.payload['associate'] = ''
          this.payload['client'] = this.addRequest.clientId
          this.payload['product'] = this.addRequest.productId
          this.payload['finishing'] = ''
          for (let i = 0; i < this.addRequest.finishingOptions.length; i++) {
            if (this.addRequest.finishingOptions[i].selected === true) {
              this.payload['finishing'] += this.addRequest.finishingOptions[i].value + '|'
            }
          }
          this.payload['finishing'] = this.payload['finishing'].substring(0, this.payload['finishing'].length - 1)
          if (this.addRequest.activeCatalogStatus1 === true) {
            this.payload['catalog'] = 0
          }
          if (this.addRequest.activeCatalogStatus2 === true) {
            this.payload['catalog'] = 1
          }
          if (this.addRequest.activeCatalogStatus3 === true) {
            this.payload['catalog'] = 2
          }
          if (this.addRequest.activeSampleStatus1 === true) {
            this.payload['sample'] = 0
          }
          if (this.addRequest.activeSampleStatus2 === true) {
            this.payload['sample'] = 3
          }
          if (this.addRequest.activeSampleStatus3 === true) {
            this.payload['sample'] = 1
          }
          if (this.addRequest.activeSampleStatus4 === true) {
            this.payload['sample'] = 2
          }
          if (this.addRequest.productLabel === 'Infogespräch') {
            this.payload['consultation'] = 1
          } else {
            this.payload['consultation'] = 0
          }
          this.payload['notePublic'] = this.addRequest.notePublic
          this.payload['notePrivate'] = this.addRequest.notePrivate
          axios({
            method: 'post',
            url: 'add_request_report.php',
            data: {
              request_id: this.payload['request_id'],
              created: this.payload['created'],
              associate: this.payload['associate'],
              client: this.payload['client'],
              product: this.payload['product'],
              finishing: this.payload['finishing'],
              quantity: this.payload['quantity'],
              catalog: this.payload['catalog'],
              sample: this.payload['sample'],
              consultation: this.payload['consultation'],
              note_public: this.payload['notePublic'],
              note_private: this.payload['notePrivate']
            }
          }).then(response => {
            if (response.data.success === false) {
              alert(response.data.error)
            } else {
              this.resetModel()
              this.$refs.grid.refresh()
              this.showAdd = false
            }
          }).catch(error => {
            alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
          })
        }
      }
    },
  },
  provide: {
    grid: [Edit, Toolbar, Resize, Search, Sort, Group]
  }
}
window.format = function(value) {
  var x = value.count
  if (x > 1) {
    x = value.count + ' Einträge'
  } else {
    x = value.count + ' Eintrag'
  }
  switch (value.field) {
    case 'test':
      return value.key + ' ' + x
    default:
      // return value.field + ': ' + value.key + ' ' + x
      return value.key + ' <span class="grey-text">(' + x + ')</span>'
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell {
  font-size: 12px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.e-grid .e-headercell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.e-grid .e-rowcell:first-child {
  padding-left: 21px !important;
}

.e-grid .e-rowcell .badge {
  font-size: 10px !important;
}

.e-grid .e-rowcell.label-column {
  font-weight: normal;
}

.e-grid .status-column {
  padding-right: 0px !important;
  padding-left: 10px !important;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.select-wrapper {
  position: relative;
  top: -8px;
}

.custom2 .select-wrapper {
  top: -24px;
}

.custom3 .select-wrapper {
  top: -24px;
}

.ql-editor {
  min-height: 145px !important;
}

#supplier-note {
  height: 145px;
}

#trader-note {
  height: 145px;
}

#client-note {
  height: 145px;
}

#delivery-note {
  height: 145px;
}

#product-description {
  height: 145px;
}

#price-note {
  height: 145px;
}

#finishing-description {
  height: 145px;
}

#incidental-description {
  height: 145px;
}

.modal-without-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: initial !important;
}

.e-search-icon {
  font-size: 20px !important;
}

.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
  width: 295px !important;
}

.e-search input {
  font-size: 17px !important;
}

.table-header-icon {
  font-size: 14px !important;
}

.status-column .e-headercelldiv {
  padding-top: 3px !important;
  padding-left: 4px !important;
}

.status-column:last-child .e-headercelldiv {
  padding-top: 1px !important;
  padding-left: 7px !important;
  color: #1976d2;
}

table.table-borderless {
  margin-bottom: 0px;
}

td.icon {
  width: 22px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 3px !important;
}

td.label, td.details {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 3px !important;
}

td.label {
  width: 85px !important;
}

table.tprices td, table.tprices th, table.tdelivery td, table.tdelivery th {
  padding-right: 10px;
}

tr.tlast td {
  font-size: 20px;
}

.tprices .form-control {
  text-align: right;
}

.currency-input::after {
  content: " €";
}

.scroll-prices {
  max-height: 665px;
  overflow-y: scroll;
}

.scroll-finishings {
  max-height: 580px;
  overflow-y: scroll;
}

td.toggle span {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.client-search {
  z-index: 9999 !important;
}

.gww-button {
  font-size: 0.84rem !important;
}

.btn-group .btn-customblue {
    color: #fff !important;
    background-color: #1976d2 !important;
}

.btn-group .btn-customblue:not(.active) {
    background-color: #eee !important;
    color: #666 !important;
}

.btn-group .btn-blue {
    background-color: #1976d2 !important;
}

.btn-group .btn-blue:not(.active) {
    background-color: #eee !important;
    color: #666 !important;
}

.gwwPriceTable th {
  color: #888 !important;
}
.gwwPriceTable td {
  color: #777 !important;
}
.dataTable {
  min-width: 300px !important;
}
.dataTables_filter {
  width: 100% !important;
}
.dataTables_filter input {
  width: 100% !important;
  margin-bottom: 15px !important;
  padding: 10px !important;
  border: 1px solid #ccc !important;
}
.dataTable thead {
  display: none !important;
}
.dataTable td {
  font-size: 16px !important;
  color: #0068b4 !important;
}
.dataTable small {
  color: #444 !important;
  display: block !important;
}
.dataTable b {
  font-weight: 500 !important;
}
#products-table td {
  font-weight: 500 !important;
}
.price-data .table-add {
  float: left !important;
  margin-bottom: 0px !important;
}
.table-add.custom i {
  font-size: 28px !important;
}
.price-data td {
  border: none !important;
  padding: 0px !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}
.price-data tbody tr td:first-child {
  vertical-align: middle !important;
  font-size: 17px !important;
  font-weight: bold !important;
  line-height: 33px !important;
  padding-left: 0px !important;
}
.price-data .table-remove {
  background-color: #d32f2f !important;
  font-size: 18px !important;
  line-height: 18px !important;
  padding: 7px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  float: right !important;
}
.price-data .table-add {
  display: none !important;
}
.compact .table {
  margin-bottom: 0px;
}

/* Hide first column */
.product-data.dataTables_wrapper tr td:nth-child(1), 
.product-data.dataTables_wrapper th:nth-child(1),
.product-data.dataTables_wrapper .row:nth-child(1) .col-sm-6:nth-child(1) {
    display: none;
}

.product-data.dataTables_wrapper tr td {
  word-break: break-word !important;
}

/* Hide first column */
.price-data.dataTables_wrapper tr td:nth-child(2), 
.price-data.dataTables_wrapper th:nth-child(2),
.price-data.dataTables_wrapper .row:nth-child(2) .col-sm-6:nth-child(2) {
    display: none;
}


.pagination .page-item .page-link {
  color: #aaa;
  font-size: 15px;
}

.pagination .page-item .page-link i {
  color: red;
}

.dataTables_info {
  color: #aaa;
  font-size: 13px;
  margin-bottom: 5px;
}

.dataTables_filter {
  float: left !important;
  margin-top: 0px !important;
}

.dataTables_filter input {
  font-size: 20px !important;
}

#add-request .modal-body {
  min-height: 678px !important;
}
</style>

